// store.js
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMusicPlayer: false,
  },

  mutations: {
    openMusicPlayer(state) {
      state.showMusicPlayer = true
    },

    closeMusicPlayer(state) {
      state.showMusicPlayer = false
    },
  },

  actions: {
    showMusicPlayer({ commit }) {
      commit('openMusicPlayer')
    },

    hideMusicPlayer({ commit }) {
      commit('closeMusicPlayer')
    },
  },
})
