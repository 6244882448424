<template>
  <div class="MusicPlayer">
    <!-- 内容容器 -->
    <transition name="fade">
      <div class="main-content" v-show="showMusicPlayer">新版网站建设中,敬请期待</div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'MusicPlayer',
  data() {
    return {}
  },
  methods: {},
  computed: {
    showMusicPlayer() {
      return this.$store.state.showMusicPlayer
    },
  },
  created() {},
  mounted() {},
  watch: {},
  props: {},
}
</script>

<style lang="scss" scoped>
.MusicPlayer {
  // 内容容器
  .main-content {
    padding: 16px;
    color: #ffffff;
    font-size: 12px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
