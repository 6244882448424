import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 屏幕基准大小
import 'amfe-flexible'
// 字体文件
import '@/assets/FontFamily/index.css'
// 全局样式
import '@/styles/index.scss'
// iconfont图标
import './assets/Iconfont/index.css'
// animate动画库
import 'wowjs/css/libs/animate.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
