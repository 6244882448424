<template>
  <div class="MusicButton">
    <!-- 按钮容器 -->
    <div class="button-content" @click="showMusicPlayer">
      <svg t="1679908318337" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13996" width="81" height="81">
        <path d="M875.6 724c0 128.5-162.8 160-363.6 160s-363.6-31.5-363.6-160S311.2 418.6 512 418.6 875.6 595.5 875.6 724z" fill="#C7F1FF" p-id="13997"></path>
        <path d="M670.153 182.974l41.402 17.882L581.74 501.409l-41.402-17.882z" fill="#0091F1" p-id="13998"></path>
        <path d="M668.1 250.2l-4.3-1.8c-20.3-8.8-29.6-32.3-20.9-52.6l21.9-50.7c8.8-20.3 32.3-29.6 52.6-20.9l4.3 1.8c20.3 8.8 29.6 32.3 20.9 52.6l-21.9 50.7c-8.8 20.4-32.3 29.7-52.6 20.9z" fill="#FFD500" p-id="13999"></path>
        <path d="M628.7 635.1c-14.9-26.7-6.9-57.2 18.2-72.4 16.6-10 29.7-23.9 36.9-40.6 21.4-49.7-17-105.1-82-133.2-64.9-28-131.7-18-153.2 31.6-7.6 17.5-8.5 37.6-3.6 57.5 6.9 28.6-9.2 55.9-38.5 64-43.2 12-78.6 39-94.2 75.2-29.9 69.3 24.1 146 114.8 185.1 90.6 39.1 183.5 25.9 213.5-43.4 16.1-37.2 10.7-83.2-11.9-123.8z" fill="#00B1FF" p-id="14000"></path>
        <path d="M738.7 193.6L656 157.9c-4-1.7-5.8-6.3-4.1-10.3l0.4-0.8c1.7-4 6.3-5.8 10.3-4.1l82.7 35.7c4 1.7 5.8 6.3 4.1 10.3l-0.4 0.8c-1.7 4-6.3 5.8-10.3 4.1zM723.4 229.1l-82.7-35.7c-4-1.7-5.8-6.3-4.1-10.3l0.4-0.8c1.7-4 6.3-5.8 10.3-4.1l82.7 35.7c4 1.7 5.8 6.3 4.1 10.3l-0.4 0.8c-1.7 3.9-6.3 5.8-10.3 4.1z" fill="#FFD500" p-id="14001"></path>
        <path d="M557.7 500.1m-47.8 0a47.8 47.8 0 1 0 95.6 0 47.8 47.8 0 1 0-95.6 0Z" fill="#FFFFFF" p-id="14002"></path>
        <path d="M493.7 744.5l-70.2-36.2c-7.4-3.8-10.3-12.8-6.5-20.2 3.8-7.4 12.8-10.3 20.2-6.5l70.2 36.2c7.4 3.8 10.3 12.8 6.5 20.2-3.8 7.4-12.9 10.3-20.2 6.5z" fill="#FFFFFF" p-id="14003"></path>
        <path d="M295.4 249.2L328 356c1.1 3.6-0.8 8-4.1 9.8-3.4 1.8-7 0.4-8.1-3.2l-32.5-106.8 12.1-6.6z" fill="#E26060" p-id="14004"></path>
        <path d="M300.199288 392.312556a19.3 23.6 25.227 1 0 20.116906-42.698361 19.3 23.6 25.227 1 0-20.116906 42.698361Z" fill="#EF8686" p-id="14005"></path>
        <path d="M390 198.6l32.5 106.8c1.1 3.6-0.8 8-4.1 9.8-3.4 1.8-7 0.4-8.1-3.2l-32.5-106.8 12.2-6.6z" fill="#E26060" p-id="14006"></path>
        <path d="M394.727761 341.793446a19.3 23.6 25.227 1 0 20.116906-42.698362 19.3 23.6 25.227 1 0-20.116906 42.698362Z" fill="#EF8686" p-id="14007"></path>
        <path d="M381.9 217.3l-82.7 44.2c-6.6 3.5-13.6 0.7-15.8-6.3-2.1-7 1.5-15.5 8-19l82.7-44.2c6.6-3.5 13.6-0.7 15.8 6.3 2.1 7-1.5 15.5-8 19z" fill="#EF8686" p-id="14008"></path>
        <path d="M793.4 476.8l-45.7 60.4c-1.5 2-4.4 2.8-6.4 1.8-2-1.1-2.3-3.6-0.8-5.6l45.7-60.4 7.2 3.8z" fill="#6EC11D" p-id="14009"></path>
        <path d="M721.797252 541.640774a12.2 14.9 58.561 1 0 25.42524-15.543397 12.2 14.9 58.561 1 0-25.42524 15.543397Z" fill="#7BD721" p-id="14010"></path>
        <path d="M849 506.5l-45.7 60.4c-1.5 2-4.4 2.8-6.4 1.8-2-1.1-2.3-3.6-0.8-5.6l45.7-60.4 7.2 3.8z" fill="#6EC11D" p-id="14011"></path>
        <path d="M777.285294 571.272286a12.2 14.9 58.561 1 0 25.425239-15.543397 12.2 14.9 58.561 1 0-25.425239 15.543397Z" fill="#7BD721" p-id="14012"></path>
        <path d="M836.7 509.7l-48.6-25.9c-3.9-2.1-4.6-6.9-1.6-10.9 3-4 8.5-5.5 12.4-3.4l48.6 25.9c3.9 2.1 4.6 6.9 1.6 10.9-2.9 3.9-8.5 5.5-12.4 3.4z" fill="#7BD721" p-id="14013"></path>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MusicButton',
  data() {
    return {}
  },
  methods: {
    // 显示音乐播放器
    showMusicPlayer() {
      if (this.isShow) {
        this.$store.dispatch('hideMusicPlayer')
      } else {
        this.$store.dispatch('showMusicPlayer')
      }
    },
  },
  computed: {
    isShow() {
      return this.$store.state.showMusicPlayer
    },
  },
  created() {},
  mounted() {},
  watch: {},
  props: {},
}
</script>

<style lang="scss" scoped>
.MusicButton {
  // 按钮容器
  .button-content {
    height: 25px;
    width: 25px;
    cursor: pointer;
    background-color: #409eff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    .icon {
      width: 70%;
      height: 70%;
    }
  }
}
</style>
