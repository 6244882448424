<template>
  <div id="app">
    <router-view />
    <!-- 音乐播放器 -->
    <div class="music-content">
      <div>
        <MusicButton class="music-button"></MusicButton>
        <MusicPlayer class="music-player"></MusicPlayer>
      </div>
    </div>
  </div>
</template>

<script>
import MusicButton from './components/MusicButton/index.vue'
import MusicPlayer from './components/MusicPlayer/index.vue'
export default {
  components: {
    MusicButton,
    MusicPlayer,
  },
  data() {
    return {}
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {
    window.parent.postMessage('loaded', '*')
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
#app {
  position: relative;
}

// 音乐播放器容器
.music-content {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);

  > div {
    height: 100%;
    width: 100%;
    position: relative;

    // 音乐播放器按钮
    .music-button {
      position: absolute;
      top: 20%;
      right: 100%;
    }

    // 音乐播放器
    .music-player {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
